import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Button, Text } from "@nextui-org/react";

import ReactGA from "react-ga4";
import { useAccount } from "wagmi";

import SearchBar from "../components/SearchBar.jsx";
import LockedCard from "../components/LockedCard.jsx";
import ProfileCard from "../components/ProfileCard.jsx";

const Home = () => {
	const navigate = useNavigate();
	const { address, isConnected } = useAccount();

	useEffect(() => {
		if(!isConnected) {
			navigate("/");
		}
	}, [navigate, isConnected]);

	if(!isConnected) {
		return(
			<Text>Wallet not detected! Redirecting...</Text>
		);
	}

	ReactGA.send({ hitType: "pageview", page: "/home" });

	return(
		<Grid.Container gap={2} justify="center" css={{ padding: 0 }}>
			<Grid xs={12}>
				<SearchBar />
			</Grid>
			<Grid xs={12} sm={6}>
				<ProfileCard address={address} imageSize={50} poweredBy={false} padding="30px" hideBorderOnMobile={false}>
					<div className="element-center"><Link to="/me"><Button>View My Stats</Button></Link></div>
				</ProfileCard>
			</Grid>
			<Grid xs={12} sm={6}>
				<LockedCard title="Coming Soon" body="" />
			</Grid>
		</Grid.Container>
	);
};

export default Home;
