import React from "react";

import LockedCard from "./LockedCard.jsx";

const SignInCard = () => {
	return(
		<LockedCard title="Sign in to unlock this content" />
	);
};

export default SignInCard;
