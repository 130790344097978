import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Text, Loading } from "@nextui-org/react";

import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import axios from "axios";
import { useAccount } from "wagmi";

import SearchBar from "../components/SearchBar.jsx";
import Trader from "../components/Trader.jsx";

const Me = () => {
	const navigate = useNavigate();
	const { address, isConnected } = useAccount();
	const [cookies] = useCookies(["signature"]);

	// 0 is waiting for response, 1 is not signed in, 2 is signed in, -1 is error
	const [requestResponse, setRequestResponse] = useState({
		status: 0,
		data: null
	});

	const onLoad = () => {
		ReactGA.send({ hitType: "pageview", page: "/me" });

		if(cookies["signature"] !== undefined) {
			axios.get(`${process.env.REACT_APP_EXPRESS_URL}/me`, {
				params: {
					address: cookies["signature"].address,
					signature: cookies["signature"].signature
				}
			})
				.then(res => {
					if(res.data.status == "error") {
						setRequestResponse({
							status: -1,
							data: res.data.error
						});
					}
					setRequestResponse({
						status: 2,
						data: res.data.data
					});
				})
				.catch(err => {
					setRequestResponse({
						status: -1,
						data: err
					});
				});
		}
		else {
			axios.get(`${process.env.REACT_APP_EXPRESS_URL}/search`, {
				params: {
					address: address
				}
			})
				.then(res => {
					if(res.data.status == "error") {
						setRequestResponse({
							status: -1,
							data: res.data.error
						});
					}
					if(res.data.data.isContract) {
						setRequestResponse({
							status: -1,
							data: "Somehow you are a contract"
						});
					}
					else {
						setRequestResponse({
							status: 1,
							data: res.data.data
						});
					}
				})
				.catch(err => {
					setRequestResponse({
						status: -1,
						data: err
					});
				});
		}
	};

	useEffect(() => {
		if(!isConnected) {
			navigate("/");
		}
	}, [isConnected]);

	useEffect(() => {
		setRequestResponse({
			status: 0,
			data: null
		});
		onLoad();
	}, [address, cookies["signature"]]);

	if(!isConnected) {
		return(
			<Text>Wallet not detected! Redirecting...</Text>
		);
	}

	const ContentHolder = () => {
		if(requestResponse.status < 0) {
			return(
				<Text>Request to backend server failed. Please check the <a href="https://discord.gg/k4RGUMNfbH" target="_blank" rel="noreferrer">Discord</a> for status updates.</Text>
			);
		}
		if(requestResponse.status == 0) {
			return(
				<Loading size="md" />
			);
		}
		if(requestResponse.status == 1) {
			return(
				<Trader address={address} data={requestResponse.data} isMe={true} isSignedIn={false} />
			);
		}
		if(requestResponse.status == 2) {
			return(
				<Trader address={address} data={requestResponse.data} isMe={true} isSignedIn={true} />
			);
		}
		return(
			<Text>Something went wrong: You should never be here</Text>
		);
	};

	return(
		<Grid.Container gap={2} justify="center" css={{ padding: 0 }}>
			<Grid xs={12}>
				<SearchBar />
			</Grid>
			<ContentHolder />
		</Grid.Container>
	);
};

export default Me;
