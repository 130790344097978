import React from "react";
import PropTypes from "prop-types";

import { useEnsName } from "wagmi";

import CustomProfileCard from "./CustomProfileCard.jsx";

const ProfileCard = (props) => {
	const ensName = useEnsName({ address: props.address });
	const truncatedAddress = `${props.address.substring(0, 6)}...${props.address.substring(38)}`;

	return(
		<CustomProfileCard address={props.address} imageSize={props.imageSize} name={ensName.data ? ensName.data : "Unknown"} underName={props.name ? props.name : truncatedAddress} canCopy={true} rightContent={props.rightContent} poweredBy={props.poweredBy} padding={props.padding} hideBorderOnMobile={props.hideBorderOnMobile}>
			{props.children}
		</CustomProfileCard>
	);
};
ProfileCard.propTypes = {
	address: PropTypes.string.isRequired,
	imageSize: PropTypes.number.isRequired,
	name: PropTypes.string,
	rightContent: PropTypes.node,
	poweredBy: PropTypes.bool.isRequired,
	padding: PropTypes.string.isRequired,
	hideBorderOnMobile: PropTypes.bool.isRequired,
	children: PropTypes.node
};

export default ProfileCard;
