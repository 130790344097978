import React from "react";
import ReactDOM from "react-dom/client";

import "./App.css";

import { createTheme, NextUIProvider } from "@nextui-org/react";

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, darkTheme as rainbowKitDarkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";

import { CookiesProvider } from "react-cookie";

import App from "./App";

const { chains, provider, webSocketProvider } = configureChains(
	[chain.mainnet],
	[
		infuraProvider({ apiKey: "80208a1961df44b1b449f54ba26fba5a" }),
		publicProvider()
	]
);
const { connectors } = getDefaultWallets({ appName: "DATALPHA", chains });
const wagmiClient = createClient({ autoConnect: true, connectors, provider, webSocketProvider});

const darkTheme = createTheme({
	type: "dark",
	theme: {
		colors: {
			gainGreen: "rgb(51, 162, 73)",
			lightOrange: "rgb(240, 134, 29)",
			darkOrange: "rgb(230, 73, 11)",
			lossRed: "rgb(238, 37, 76)",
			whiteWhenLight: "black",
			blackWhenLight: "white"
		}
	}
});

const Wrapper = () => {
	return(
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains} theme={rainbowKitDarkTheme()}>
				<NextUIProvider theme={darkTheme}>
					<CookiesProvider>
						<App />
					</CookiesProvider>
				</NextUIProvider>
			</RainbowKitProvider>
		</WagmiConfig>
	);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Wrapper />
);
