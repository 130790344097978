import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Text, Loading } from "@nextui-org/react";

import ReactGA from "react-ga4";
import axios from "axios";

import SearchBar from "../components/SearchBar.jsx";
import Trader from "../components/Trader.jsx";

const TraderOrCollection = () => {
	const { address } = useParams();

	// 0 is waiting for response, 1 is trader, 2 is collection, -1 is error
	const [requestResponse, setRequestResponse] = useState({
		status: 0,
		data: null
	});

	const onLoad = () => {
		ReactGA.send({ hitType: "pageview", page: "/trader_or_collection" });

		axios.get(`${process.env.REACT_APP_EXPRESS_URL}/search`, {
			params: {
				address: address
			}
		})
			.then(res => {
				if(res.data.status == "error") {
					setRequestResponse({
						status: -1,
						data: res.data.error
					});
				}
				if(res.data.data.isContract) {
					setRequestResponse({
						status: 2,
						data: res.data.data
					});
				}
				else {
					setRequestResponse({
						status: 1,
						data: res.data.data
					});
				}
			})
			.catch(err => {
				setRequestResponse({
					status: -1,
					data: err
				});
			});
	};

	useEffect(() => {
		setRequestResponse({
			status: 0,
			data: null
		});
		onLoad();
	}, [address]);

	const ContentHolder = () => {
		if(requestResponse.status < 0) {
			return(
				<Text>Request to backend server failed. Please check the <a href="https://discord.gg/k4RGUMNfbH" target="_blank" rel="noreferrer">Discord</a> for status updates.</Text>
			);
		}
		if(requestResponse.status == 0) {
			return(
				<Loading size="md" />
			);
		}
		if(requestResponse.status == 1) {
			return(
				<Trader address={address} data={requestResponse.data} isMe={false} isSignedIn={false} />
			);
		}
		if(requestResponse.status == 2) {
			return(
				<Text>Collections will be supported in the future</Text>
			);
		}
		return(
			<Text>Something went wrong: You should never be here</Text>
		);
	};

	return(
		<Grid.Container gap={2} justify="center" css={{ padding: 0 }}>
			<Grid xs={12}>
				<SearchBar />
			</Grid>
			<ContentHolder />
		</Grid.Container>
	);
};

export default TraderOrCollection;
