import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Spacer, Text, Tooltip } from "@nextui-org/react";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

import UnlockedCard from "./UnlockedCard.jsx";

const CustomProfileCard = (props) => {
	const [copyClicked, setCopyClicked] = useState(false);

	if(!props.address) {
		return(<></>);
	}

	const handleCopyClicked = () => {
		navigator.clipboard.writeText(props.address);
		setCopyClicked(true);
	};

	const CopyIcon = () => {
		return(
			<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="3" y="2" width="8" height="10" rx="1" stroke="currentColor"/>
				<path d="M13 4V12C13 13.1046 12.1046 14 11 14H5" stroke="currentColor" strokeLinecap="round"/>
			</svg>
		);
	};

	const CopiedIcon = () => {
		return(
			<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14 3L8.70277 9.1801C8.32368 9.62237 7.64831 9.64831 7.23641 9.23641L5 7" stroke="currentColor" strokeLinecap="round"/>
				<path d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C8.70127 2 9.37444 2.12031 10 2.34141" stroke="currentColor" strokeLinecap="round"/>
			</svg>
		);
	};

	return(
		<UnlockedCard poweredBy={props.poweredBy} padding={props.padding} hideBorderOnMobile={props.hideBorderOnMobile}>
			<Container fluid display="flex" justify="flex-start" gap={0}>
				<div>
					<Jazzicon diameter={props.imageSize} seed={jsNumberForAddress(props.address)} />
				</div>
				<div style={{ marginLeft: "30px" }}>
					<div style={{ margin: 0 }}>
						<Text h4>{props.name}</Text>
						{
							props.canCopy
								? <Tooltip content={copyClicked ? "Copied!" : "Copy"}>
									<Text color="grey" style={{ cursor: "pointer" }} onClick={handleCopyClicked}>{props.underName} {copyClicked ? <CopiedIcon /> : <CopyIcon address={props.address} />}</Text>
								</Tooltip>
								: <Text color="grey">{props.underName}</Text>
						}
					</div>
				</div>
				<div style={{ marginLeft: "auto" }}>
					{props.rightContent}
				</div>
			</Container>
			<Spacer y={1} />
			{props.children}
		</UnlockedCard>
	);
};
CustomProfileCard.propTypes = {
	address: PropTypes.string.isRequired,
	imageSize: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	underName: PropTypes.string.isRequired,
	canCopy: PropTypes.bool.isRequired,
	rightContent: PropTypes.node,
	poweredBy: PropTypes.bool.isRequired,
	padding: PropTypes.string.isRequired,
	hideBorderOnMobile: PropTypes.bool.isRequired,
	children: PropTypes.node
};

export default CustomProfileCard;
