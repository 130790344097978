import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, Grid, Table, Text, Input, Dropdown, Tooltip, Spacer, Loading, Button } from "@nextui-org/react";
// This exact order of imports must be used for the chart
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { Chart } from "react-chartjs-2";

import ReactGA from "react-ga4";
import axios from "axios";

import SignInCard from "../components/SignInCard.jsx";
import UnlockedCard from "../components/UnlockedCard.jsx";
import CustomProfileCard from "../components/CustomProfileCard.jsx";
import ProfileCard from "../components/ProfileCard.jsx";
import WalletGroupCard from "../components/WalletGroupCard.jsx";
import PoweredBy from "../components/PoweredBy.jsx";
import GasIcon from "../components/GasIcon.jsx";

const Trader = (props) => {
	const round = (num) => {
		return parseFloat(Number(num).toFixed(3));
	};

	const NftImage = (props) => {
		const [src, setSrc] = useState("");
		let mounted = true;

		const getNftImage = () => {
			axios.get(`${process.env.REACT_APP_EXPRESS_URL}/getNftImage`, {
				params: {
					nftContractAddress: props.position.nftContractAddress,
					nftID: props.position.nftID
				}
			})
				.then(res => {
					if(res.data.status === "error") {
						console.log(res.data.error);
					}
					else if(res.data.data === null) {
						setTimeout(() => {
							if(mounted && process.env.REACT_APP_RETRY_IMAGES) {
								getNftImage();
							}
						}, 500);
					}
					else {
						setSrc(res.data.data.imageUrl);
					}
				})
				.catch(err => {
					console.log(err);
				});
		};

		useEffect(() => {
			getNftImage();

			return () => {
				mounted = false;
			};
		}, [props.position]);

		return(
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
				<img src={src} className="nft-image" alt="" />
				<div className="nft-image-text">
					<Text size="$lg">{props.position.nftContractName}</Text>
					<Text size="$xs" color="grey">{`#${props.position.nftID}`}</Text>
				</div>
			</div>
		);
	};
	NftImage.propTypes = {
		position: PropTypes.object.isRequired
	};

	const CollectionImage = (props) => {
		const [info, setInfo] = useState(null);
		let mounted = true;

		const getCollectionImage = () => {
			axios.get(`${process.env.REACT_APP_EXPRESS_URL}/getCollectionInfo`, {
				params: {
					collectionAddress: props.collection.address,
					exampleNftID: props.collection.exampleNftID
				}
			})
				.then(res => {
					if(res.data.status === "error") {
						console.log(res.data.error);
					}
					else if(res.data.data === null) {
						setTimeout(() => {
							if(mounted && process.env.REACT_APP_RETRY_IMAGES) {
								getCollectionImage();
							}
						}, 500);
					}
					else {
						setInfo(res.data.data);
					}
				})
				.catch(err => {
					console.log(err);
				});
		};

		useEffect(() => {
			getCollectionImage();

			return () => {
				mounted = false;
			};
		}, [props.collection]);

		const content = <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
			<img src={info == null ? "" : info.imageUrl} className="nft-image" alt="" />
			<div className="nft-image-text">
				<Text size="$lg">{props.collection.name}</Text>
			</div>
		</div>;

		if(info == null) {
			return(
				content
			);
		}
		return(
			<a href={`https://opensea.io/assets/ethereum/${info.address}`} target="_blank" rel="noreferrer">
				{content}
			</a>
		);
	};
	CollectionImage.propTypes = {
		collection: PropTypes.object.isRequired
	};

	const TransactionTypeLabel = (props) => {
		let textColor, backgroundColor;
		if(props.type == "Mint" || props.type == "Airdrop") {
			textColor = "rgba(21, 107, 54, 1)";
			backgroundColor = "rgba(203, 247, 218, 0.8)";
		}
		else if(props.type == "Burn") {
			textColor = "rgba(254, 226, 226, 1)";
			backgroundColor = "rgba(239, 68, 68, 0.8)";
		}
		else if(props.type == "Buy" || props.type == "Sell") {
			textColor = "rgba(27, 86, 122, 1)";
			backgroundColor = "rgba(200, 235, 250, 0.8)";
		}
		else if(props.type == "Buy WETH" || props.type == "Sell WETH") {
			textColor = "rgba(87, 27, 122, 1)";
			backgroundColor = "rgba(200, 207, 250, 0.8)";
		}
		else if(props.type == "Receive" || props.type == "Send") {
			textColor = "rgba(113, 63, 18, 1)";
			backgroundColor = "rgba(250, 238, 202, 0.8)";
		}
		return(
			<span className="transaction-type-label" style={{ color: textColor, backgroundColor: backgroundColor }}>{props.type}</span>
		);
	};
	TransactionTypeLabel.propTypes = {
		type: PropTypes.string.isRequired
	};

	const [collectionSearchInput, setCollectionSearchInput] = useState("");
	const CollectionSearch = () => {
		const [collectionTempSearchInput, setCollectionTempSearchInput] = useState(collectionSearchInput);

		const onKeyDown = (event) => {
			if(event.key != "Enter") {
				return;
			}
			setCollectionSearchInput(collectionTempSearchInput);
		};
		const onBlur = () => {
			setCollectionSearchInput(collectionTempSearchInput);
		};

		return(
			<Input placeholder="Search by collection name" width="250px" value={collectionTempSearchInput} bordered color="primary" animated={false} onInput={e => setCollectionTempSearchInput(e.target.value)} onKeyDown={onKeyDown} onBlur={onBlur} />
		);
	};

	const [timePeriodDropdownSelected, setTimePeriodDropdownSelected] = useState("allTime");
	const TimePeriodDropdown = () => {
		const currentDate = new Date();

		const getDateString = (monthsAgo) => {
			const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsAgo);
			return date.toLocaleDateString("en-US", { month: "long", year: "numeric" });
		};

		const getQuarterString = (quartersAgo) => {
			const adjustedMonth = currentDate.getMonth() - quartersAgo * 3;
			const year = currentDate.getFullYear() - Math.floor(adjustedMonth / 12);
			const quarter = adjustedMonth < 0 ? Math.ceil((12 + adjustedMonth) / 3) : Math.ceil((adjustedMonth + 1) / 3);
			return `Q${quarter} ${year}`;
		};

		const timePeriodNames = {
			allTime: "All Time",
			sevenDays: "Last 7 Days",
			thirtyDays: "Last 30 Days",
			thisMonth: "Month to Date",
			oneMonthAgo: getDateString(1),
			twoMonthsAgo: getDateString(2),
			thisQuarter: "This Quarter",
			oneQuarterAgo: getQuarterString(1),
			twoQuartersAgo: getQuarterString(2),
			threeQuartersAgo: getQuarterString(3),
			yearToDate: "Year to Date",
			lastYear: `${currentDate.getFullYear() - 1}`
		};

		const getSelectedName = () => timePeriodNames[timePeriodDropdownSelected] || "Error";

		return (
			<Dropdown>
				<Dropdown.Button bordered>{getSelectedName()}</Dropdown.Button>
				<Dropdown.Menu selectionMode="single" selectedKeys={timePeriodDropdownSelected} onSelectionChange={(keys) => setTimePeriodDropdownSelected(keys.currentKey)}>
					{Object.entries(timePeriodNames).map(([key, name]) => (
						<Dropdown.Item key={key}>{name}</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const [sortDropdownSelected, setSortDropdownSelected] = useState("newest");
	const SortDropdown = () => {
		const getSelectedName = () => {
			return sortDropdownSelected.charAt(0).toUpperCase() + sortDropdownSelected.slice(1);
		};
		return(
			<Dropdown>
				<Dropdown.Button bordered>Sort by: {getSelectedName()}</Dropdown.Button>
				<Dropdown.Menu selectionMode="single" selectedKeys={sortDropdownSelected} onSelectionChange={(keys) => setSortDropdownSelected(keys.currentKey)}>
					<Dropdown.Item key="newest">Newest</Dropdown.Item>
					<Dropdown.Item key="oldest">Oldest</Dropdown.Item>
					<Dropdown.Item key="profit">Profit</Dropdown.Item>
					<Dropdown.Item key="loss">Loss</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const [transactionTypeFilterDropdownSelected, setTransactionTypeFilterDropdownSelected] = useState(["mint", "airdrop", "burn", "buy", "sell", "buyWeth", "sellWeth", "receive"]);
	const TransactionTypeFilterDropdown = () => {
		return(
			<Dropdown>
				<Dropdown.Button bordered>Filter</Dropdown.Button>
				<Dropdown.Menu selectionMode="multiple" selectedKeys={transactionTypeFilterDropdownSelected} onSelectionChange={(keys) => setTransactionTypeFilterDropdownSelected(Array.from(keys))}>
					<Dropdown.Section title="Contract">
						<Dropdown.Item key="mint">Mint</Dropdown.Item>
						<Dropdown.Item key="airdrop">Airdrop</Dropdown.Item>
						<Dropdown.Item key="burn">Burn</Dropdown.Item>
					</Dropdown.Section>
					<Dropdown.Section title="Purchase">
						<Dropdown.Item key="buy">Buy</Dropdown.Item>
						<Dropdown.Item key="sell">Sell</Dropdown.Item>
						<Dropdown.Item key="buyWeth">Buy WETH</Dropdown.Item>
						<Dropdown.Item key="sellWeth">Sell WETH</Dropdown.Item>
					</Dropdown.Section>
					<Dropdown.Section title="Transfer">
						<Dropdown.Item key="receive">Receive</Dropdown.Item>
						<Dropdown.Item key="send">Send</Dropdown.Item>
					</Dropdown.Section>
				</Dropdown.Menu>
			</Dropdown>
		);
	};
	const getTransactionTypeKey = (transactionType) => {
		transactionType = transactionType.toLowerCase().split(" ").map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join("");
		transactionType = transactionType.charAt(0).toLowerCase() + transactionType.slice(1);
		return transactionType;
	};

	const ConfigPanel = () => {
		return(
			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
				<CollectionSearch />
				<TimePeriodDropdown />
				<SortDropdown />
				<TransactionTypeFilterDropdown />
			</div>
		);
	};

	const getStartAndEndDates = (timePeriod) => {
		let startDate, endDate;
		const currentDate = new Date();

		const getQuarterStartDate = (quartersAgo) => {
			let year = currentDate.getFullYear();
			let month = currentDate.getMonth() - quartersAgo * 3;
			while (month < 0) {
				month += 12;
				year--;
			}
			const quarterStartMonth = month - (month % 3);
			return new Date(year, quarterStartMonth, 1);
		};
		const getQuarterEndDate = (quartersAgo) => {
			const startOfNextQuarter = getQuarterStartDate(quartersAgo - 1);
			const endOfQuarter = new Date(startOfNextQuarter);
			endOfQuarter.setDate(endOfQuarter.getDate() - 1);
			return endOfQuarter;
		};

		switch(timePeriod) {
		case "allTime":
			startDate = new Date(0);
			endDate = currentDate;
			break;
		case "sevenDays":
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
			endDate = currentDate;
			break;
		case "thirtyDays":
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
			endDate = currentDate;
			break;
		case "thisMonth":
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			endDate = currentDate;
			break;
		case "oneMonthAgo":
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			break;
		case "twoMonthsAgo":
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
			break;
		case "thisQuarter":
			startDate = getQuarterStartDate(0);
			endDate = currentDate;
			break;
		case "oneQuarterAgo":
			startDate = getQuarterStartDate(1);
			endDate = getQuarterEndDate(1);
			break;
		case "twoQuartersAgo":
			startDate = getQuarterStartDate(2);
			endDate = getQuarterEndDate(2);
			break;
		case "threeQuartersAgo":
			startDate = getQuarterStartDate(3);
			endDate = getQuarterEndDate(3);
			break;
		case "yearToDate":
			startDate = new Date(currentDate.getFullYear(), 0, 1);
			endDate = currentDate;
			break;
		case "lastYear":
			startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
			endDate = new Date(currentDate.getFullYear() - 1, 11, 31);
			break;
		default:
			startDate = endDate = null;
		}
		return { startDate, endDate };
	};
	const computeDataSubset = (data) => {
		let openPositions = computeDataSubsetInternalPositions(data.openPositions);
		let closedPositions = computeDataSubsetInternalPositions(data.closedPositions);

		let stats = {
			open: computeDataSubsetInternalStats(openPositions),
			closed: computeDataSubsetInternalStats(closedPositions),
			both: computeDataSubsetInternalStats(openPositions.concat(closedPositions))
		};

		let collections = [];
		collections = computeDataSubsetInternalCollections(collections, openPositions);
		collections = computeDataSubsetInternalCollections(collections, closedPositions);
		collections = computeDataSubsetInternalCollectionsEnd(collections);

		return {
			closedPositions: closedPositions,
			stats: stats,
			collections: collections
		};
	};
	const computeDataSubsetInternalPositions = (positions) => {
		let { startDate, endDate } = getStartAndEndDates(timePeriodDropdownSelected);

		let filteredPositions = [];
		for(let i = 0; i < positions.length; i++) {
			const position = positions[i];

			// Search
			if(collectionSearchInput != "") {
				if(!position.nftContractName.toLowerCase().includes(collectionSearchInput.toLowerCase())) {
					continue;
				}
			}

			// Time period
			if (startDate !== null && endDate !== null &&
				(position.recentActivityTimestamp < startDate.getTime() / 1000
				|| position.recentActivityTimestamp > endDate.getTime() / 1000)) {
				continue;
			}

			// Transaction type
			let allowInType = false, allowOutType = false;
			for(let j = 0; j < transactionTypeFilterDropdownSelected.length; j++) {
				const transactionType = transactionTypeFilterDropdownSelected[j];
				if(transactionType == getTransactionTypeKey(position.inType)) {
					allowInType = true;
				}
				if(transactionType == getTransactionTypeKey(position.outType)) {
					allowOutType = true;
				}
			}
			if(!allowInType || (position.outType != "" && !allowOutType)) {
				continue;
			}

			filteredPositions.push(position);
		}

		// Sort
		if(sortDropdownSelected == "newest") {
			filteredPositions.sort((a, b) => b.recentActivityTimestamp - a.recentActivityTimestamp);
		}
		else if(sortDropdownSelected == "oldest") {
			filteredPositions.sort((a, b) => a.recentActivityTimestamp - b.recentActivityTimestamp);
		}
		else if(sortDropdownSelected == "profit") {
			filteredPositions.sort((a, b) => b.profit - a.profit);
		}
		else if(sortDropdownSelected == "loss") {
			filteredPositions.sort((a, b) => a.profit - b.profit);
		}

		return filteredPositions;
	};
	const computeDataSubsetInternalStats = (positions) => {
		let stats = {
			wins: 0,
			losses: 0,
			ethSpent: 0,
			ethReceived: 0,
			gasCost: 0,
			profit: 0
		};

		for(let i = 0; i < positions.length; i++) {
			const position = positions[i];
			if(position.profit > 0) {
				stats.wins++;
			}
			else if(position.profit < 0) {
				stats.losses++;
			}
			stats.ethSpent += position.ethSpent;
			stats.ethReceived += position.ethReceived;
			stats.gasCost += position.gasCost;
			stats.profit += position.profit;
		}

		stats.ethSpent = round(stats.ethSpent);
		stats.ethReceived = round(stats.ethReceived);
		stats.gasCost = round(stats.gasCost);
		stats.profit = round(stats.profit);

		return stats;
	};
	const computeDataSubsetInternalCollections = (collections, positions) => {
		for(let i = 0; i < positions.length; i++) {
			const position = positions[i];

			let collectionIndex = -1;
			for(let j = 0; j < collections.length; j++) {
				const collection = collections[j];
				if(collection.address == position.nftContractAddress) {
					collectionIndex = j;
					break;
				}
			}
			if(collectionIndex == -1) {
				collections.push({
					address: position.nftContractAddress,
					name: position.nftContractName,
					exampleNftID: position.nftID,
					ethSpent: 0,
					ethReceived: 0,
					gasCost: 0,
					currentProfit: 0,
					roiIsInfinite: false,
					roi: 0,
					held: 0,
					sold: 0,
					ethSold: 0,
					ethSoldAvg: 0,
					gasSold: 0,
					gasSoldAvg: 0,
					minted: 0,
					ethMinted: 0,
					ethMintedAvg: 0,
					gasMinted: 0,
					gasMintedAvg: 0,
					bought: 0,
					ethBought: 0,
					ethBoughtAvg: 0,
					gasBought: 0,
					gasBoughtAvg: 0,
					recentActivityTimestamp: 0
				});
				collectionIndex = collections.length - 1;
			}

			collections[collectionIndex].ethSpent += position.ethSpent;
			collections[collectionIndex].ethReceived += position.ethReceived;
			collections[collectionIndex].gasCost += position.gasCost;
			collections[collectionIndex].currentProfit += position.profit;

			if(position.open) {
				collections[collectionIndex].held++;
			}

			if(position.inType == "Mint") {
				collections[collectionIndex].minted++;
				collections[collectionIndex].ethMinted += position.ethSpent;
				collections[collectionIndex].gasMinted += position.gasCost;
			}
			else if(position.inType == "Buy" || position.inType == "Buy WETH") {
				collections[collectionIndex].bought++;
				collections[collectionIndex].ethBought += position.ethSpent;
				collections[collectionIndex].gasBought += position.gasCost;
			}

			if(position.outType == "Sell" || position.outType == "Sell WETH") {
				collections[collectionIndex].sold++;
				collections[collectionIndex].ethSold += position.ethReceived;
				collections[collectionIndex].gasSold += position.gasCost;
			}

			if(position.recentActivityTimestamp > collections[collectionIndex].recentActivityTimestamp) {
				collections[collectionIndex].recentActivityTimestamp = position.recentActivityTimestamp;
			}
		}

		return collections;
	};
	const computeDataSubsetInternalCollectionsEnd = (collections) => {
		for(let i = 0; i < collections.length; i++) {
			const collection = collections[i];

			if(collections[i].ethSpent + collection.gasCost != 0) {
				collections[i].roiIsInfinite = false;
				collections[i].roi = collection.currentProfit / (collection.ethSpent + collection.gasCost);
			}
			else {
				collections[i].roiIsInfinite = true;
			}

			collections[i].ethMintedAvg = collections[i].minted == 0 ? 0 : round(collections[i].ethMinted / collections[i].minted);
			collections[i].ethBoughtAvg = collections[i].bought == 0 ? 0 : round(collections[i].ethBought / collections[i].bought);
			collections[i].ethSoldAvg = collections[i].sold == 0 ? 0 : round(collections[i].ethSold / collections[i].sold);

			collections[i].ethSpent = round(collection.ethSpent);
			collections[i].ethReceived = round(collection.ethReceived);
			collections[i].gasCost = round(collection.gasCost);
			collections[i].currentProfit = round(collection.currentProfit);

			collections[i].ethMinted = round(collection.ethMinted);
			collections[i].ethBought = round(collection.ethBought);
			collections[i].ethSold = round(collection.ethSold);

			collections[i].gasMintedAvg = collections[i].minted == 0 ? 0 : round(collections[i].gasMinted / collections[i].minted);
			collections[i].gasBoughtAvg = collections[i].bought == 0 ? 0 : round(collections[i].gasBought / collections[i].bought);
			collections[i].gasSoldAvg = collections[i].sold == 0 ? 0 : round(collections[i].gasSold / collections[i].sold);

			collections[i].gasMinted = round(collection.gasMinted);
			collections[i].gasBought = round(collection.gasBought);
			collections[i].gasSold = round(collection.gasSold);
		}

		// Sort
		if(sortDropdownSelected == "newest") {
			collections.sort((a, b) => b.recentActivityTimestamp - a.recentActivityTimestamp);
		}
		else if(sortDropdownSelected == "oldest") {
			collections.sort((a, b) => a.recentActivityTimestamp - b.recentActivityTimestamp);
		}
		else if(sortDropdownSelected == "profit") {
			collections.sort((a, b) => b.currentProfit - a.currentProfit);
		}
		else if(sortDropdownSelected == "loss") {
			collections.sort((a, b) => a.currentProfit - b.currentProfit);
		}

		return collections;
	};
	const dataSubset = useMemo(() => computeDataSubset(props.data.traderData), [props.data, collectionSearchInput, timePeriodDropdownSelected, sortDropdownSelected, transactionTypeFilterDropdownSelected]);

	const StatsPanel = () => {
		if(dataSubset.stats == null) {
			return(
				<Loading size="md" />
			);
		}

		return(
			<Grid.Container>
				<Grid xs={12}>
					<div className="text-center width-100">
						<Text size="$xl">Summary</Text>
					</div>
				</Grid>
				<StatsCategory category="both" hideMobile={false} />
				<Grid xs={12} className="hide-mobile">
					<Spacer y={2} />
				</Grid>
				<Grid xs={12} className="hide-mobile">
					<div className="text-center width-100">
						<Text size="$xl">Closed Positions</Text>
					</div>
				</Grid>
				<StatsCategory category="closed" hideMobile={true} />
				<Grid xs={12} className="hide-mobile">
					<Spacer y={2} />
				</Grid>
				<Grid xs={12} className="hide-mobile">
					<div className="text-center width-100">
						<Text size="$xl">Open Positions</Text>
					</div>
				</Grid>
				<StatsCategory category="open" hideMobile={true} />
			</Grid.Container>
		);
	};

	const StatsCategory = (props) => {
		return(
			<>
				{
					props.category == "closed"
						? <>
							<Grid xs={6} className={props.hideMobile ? "hide-mobile" : ""}>
								<div className="text-center width-100">
									<Text color="grey">Wins</Text>
									<Text h3 weight="bold" color="$gainGreen">{dataSubset.stats[props.category].wins}</Text>
								</div>
							</Grid>
							<Grid xs={6} className={props.hideMobile ? "hide-mobile" : ""}>
								<div className="text-center width-100">
									<Text color="grey">Losses</Text>
									<Text h3 weight="bold" color="$lossRed">{dataSubset.stats[props.category].losses}</Text>
								</div>
							</Grid>
						</>
						: <></>
				}
				<Grid xs={6} className={props.hideMobile ? "hide-mobile" : ""}>
					<div className="text-center width-100">
						<Text color="grey">Total Spent</Text>
						<Text h3 weight="bold">{dataSubset.stats[props.category].ethSpent} Ξ</Text>
					</div>
				</Grid>
				<Grid xs={6} className={props.hideMobile ? "hide-mobile" : ""}>
					<div className="text-center width-100">
						<Text color="grey">Total {props.category == "both" ? "Value" : "Received"}</Text>
						<Text h3 weight="bold">{dataSubset.stats[props.category].ethReceived} Ξ</Text>
					</div>
				</Grid>
				<Grid xs={6} className={props.hideMobile ? "hide-mobile" : ""}>
					<div className="text-center width-100">
						<Text color="grey">Total Gas</Text>
						<Text h3 weight="bold">{dataSubset.stats[props.category].gasCost} Ξ</Text>
					</div>
				</Grid>
				<Grid xs={6} className={props.hideMobile ? "hide-mobile" : ""}>
					<div className="text-center width-100">
						<Text color="grey">Total {props.category == "closed" ? "Realized" : (props.category == "open" ? "Unrealized" : "")} Profit</Text>
						<Text h3 weight="bold">{dataSubset.stats[props.category].profit > 0 ? "+" : ""}{dataSubset.stats[props.category].profit} Ξ</Text>
					</div>
				</Grid>
			</>
		);
	};
	StatsCategory.propTypes = {
		category: PropTypes.string.isRequired,
		hideMobile: PropTypes.bool.isRequired
	};

	const FlipsPanel = () => {
		return(
			<>
				<Text h3>Flips</Text>
				<FlipsTable />
			</>
		);
	};

	const FlipsTable = () => {
		if(dataSubset.closedPositions == null) {
			return(
				<Loading size="md" />
			);
		}
		if(dataSubset.closedPositions.length == 0) {
			return(
				<Text>No data</Text>
			);
		}

		let rows = [];
		for(let i = 0; i < dataSubset.closedPositions.length; i++) {
			const position = dataSubset.closedPositions[i];

			rows.push(
				<Table.Row key={i}>
					<Table.Cell>
						<a href={`https://opensea.io/assets/ethereum/${position.nftContractAddress}/${position.nftID}`} target="_blank" rel="noreferrer">
							<NftImage position={position} />
						</a>
					</Table.Cell>
					<Table.Cell>
						<Tooltip content={`${position.inDuration} ago`}>
							<Text>
								<Text as="span" className="trader-value">{position.ethSpent} Ξ</Text>
								<br className="transaction-br" />
								{<TransactionTypeLabel type={position.inType} />}
							</Text>
							<Text size="$xs" color="grey" css={{ "@mdMax": { display: "none" } }}><GasIcon /> {position.inGasCost} Ξ</Text>
						</Tooltip>
					</Table.Cell>
					<Table.Cell>
						<Tooltip content={`${position.outDuration} ago`}>
							<Text>
								<Text as="span" className="trader-value">{position.ethReceived} Ξ</Text>
								<br className="transaction-br" />
								{<TransactionTypeLabel type={position.outType} />}
							</Text>
							<Text size="$xs" color="grey" css={{ "@mdMax": { display: "none" } }}><GasIcon /> {position.outGasCost} Ξ</Text>
						</Tooltip>
					</Table.Cell>
					<Table.Cell>
						<Text>
							<Text as="span" className="trader-value" css={{ color: position.profit > 0 ? "$gainGreen" : (position.profit < 0 ? "$lossRed" : "") }}>{position.profit > 0 ? "+" : ""}{position.profit} Ξ</Text>
						</Text>
						{
							!position.roiIsInfinite
								? <Text size="$xs" color={position.profit > 0 ? "$gainGreen" : (position.profit < 0 ? "$lossRed" : "")}>{position.roi > 0 ? "+" : ""}{Math.round(position.roi * 100)}%</Text>
								: <></>
						}
					</Table.Cell>
					<Table.Cell css={{ "@xsMax": { display: "none" } }}>
						<Tooltip content={`${position.outDuration} ago`}>
							<Text size="$lg" weight="bold">{position.holdDuration}</Text>
							<Text size="$xs" color="grey">{new Date(position.outTimestamp * 1000).toLocaleDateString("en-US")}</Text>
						</Tooltip>
					</Table.Cell>
				</Table.Row>
			);
		}

		return(
			<Table shadow={false} compact css={{ padding: 0 }}>
				<Table.Header>
					<Table.Column>NFT</Table.Column>
					<Table.Column>Entry</Table.Column>
					<Table.Column>Exit</Table.Column>
					<Table.Column>Profit</Table.Column>
					<Table.Column className="hide-mobile">Time Held</Table.Column>
				</Table.Header>
				<Table.Body>
					{rows}
				</Table.Body>
				<Table.Pagination align="center" rowsPerPage={10} />
			</Table>
		);
	};

	const CollectionsPanel = () => {
		return(
			<>
				<Text h3>Collections</Text>
				<CollectionsTable />
				<CollectionModal />
			</>
		);
	};

	const [collectionSelected, setCollectionSelected] = useState({
		open: false,
		collection: null
	});
	const CollectionsTable = () => {
		if(dataSubset.collections == null) {
			return(
				<Loading size="md" />
			);
		}
		if(dataSubset.collections.length == 0) {
			return(
				<Text>No data</Text>
			);
		}

		let rows = [];
		for(let i = 0; i < dataSubset.collections.length; i++) {
			const collection = dataSubset.collections[i];

			rows.push(
				<Table.Row key={i}>
					<Table.Cell>
						<CollectionImage collection={collection} />
					</Table.Cell>
					<Table.Cell css={{ "@xsMax": { display: "none" } }}>
						<Text>
							<Text as="span" className="trader-value">{collection.ethMinted} Ξ</Text>
						</Text>
						<Text size="$xs" color="grey" css={{ "@mdMax": { display: "none" } }}>{collection.minted} NFT{collection.minted == 1 ? "" : "s"}</Text>
					</Table.Cell>
					<Table.Cell css={{ "@xsMax": { display: "none" } }}>
						<Text>
							<Text as="span" className="trader-value">{collection.ethBought} Ξ</Text>
						</Text>
						<Text size="$xs" color="grey" css={{ "@mdMax": { display: "none" } }}>{collection.bought} NFT{collection.bought == 1 ? "" : "s"}</Text>
					</Table.Cell>
					<Table.Cell css={{ "@xsMin": { display: "none" } }}>
						<Text>
							<Text as="span" className="trader-value">{collection.ethSpent} Ξ</Text>
						</Text>
						<Text size="$xs" color="grey" css={{ "@mdMax": { display: "none" } }}>{collection.minted + collection.bought} NFT{collection.minted + collection.bought == 1 ? "" : "s"}</Text>
					</Table.Cell>
					<Table.Cell css={{ "@xsMax": { display: "none" } }}>
						<Text>
							<Text as="span" className="trader-value">{collection.ethSold} Ξ</Text>
						</Text>
						<Text size="$xs" color="grey" css={{ "@mdMax": { display: "none" } }}>{collection.sold} NFT{collection.sold == 1 ? "" : "s"}</Text>
					</Table.Cell>
					<Table.Cell>
						<Text>
							<Text as="span" className="trader-value">{collection.held}</Text>
						</Text>
					</Table.Cell>
					<Table.Cell>
						<Text>
							<Text as="span" className="trader-value" css={{ color: collection.currentProfit > 0 ? "$gainGreen" : (collection.currentProfit < 0 ? "$lossRed" : "") }}>{collection.currentProfit > 0 ? "+" : ""}{collection.currentProfit} Ξ</Text>
						</Text>
						{
							!collection.roiIsInfinite
								? <Text size="$xs" color={collection.currentProfit > 0 ? "$gainGreen" : (collection.currentProfit < 0 ? "$lossRed" : "")}>{collection.roi > 0 ? "+" : ""}{Math.round(collection.roi * 100)}%</Text>
								: <></>
						}
					</Table.Cell>
					<Table.Cell>
						<Button size="sm" auto onClick={() => handleCollectionModalOpen(collection)}>View</Button>
					</Table.Cell>
				</Table.Row>
			);
		}

		return(
			<Table shadow={false} compact css={{ padding: 0 }}>
				<Table.Header>
					<Table.Column>Collection</Table.Column>
					<Table.Column css={{ "@xsMax": { display: "none" } }}>Minted</Table.Column>
					<Table.Column css={{ "@xsMax": { display: "none" } }}>Bought</Table.Column>
					<Table.Column css={{ "@xsMin": { display: "none" } }}>Spent</Table.Column>
					<Table.Column css={{ "@xsMax": { display: "none" } }}>Sold</Table.Column>
					<Table.Column>Holding</Table.Column>
					<Table.Column>Current Profit</Table.Column>
					<Table.Column>Details</Table.Column>
				</Table.Header>
				<Table.Body>
					{rows}
				</Table.Body>
				<Table.Pagination align="center" rowsPerPage={5} />
			</Table>
		);
	};

	const handleCollectionModalOpen = (collection) => {
		ReactGA.event("select_content", { content_type: "view_collection_details", item_id: collection.address });

		setCollectionSelected({
			open: true,
			collection: collection
		});
	};

	const CollectionModal = () => {
		const [info, setInfo] = useState(null);

		const getCollectionInfo = () => {
			if(!collectionSelected.open) {
				return;
			}

			axios.get(`${process.env.REACT_APP_EXPRESS_URL}/getCollectionInfo`, {
				params: {
					collectionAddress: collectionSelected.collection.address,
					exampleNftID: collectionSelected.collection.exampleNftID
				}
			})
				.then(res => {
					if(res.data.status === "error") {
						console.log(res.data.error);
					}
					else if(res.data.data === null) {
						setTimeout(() => {
							if(process.env.REACT_APP_RETRY_IMAGES) {
								getCollectionInfo();
							}
						}, 500);
					}
					else {
						let info = res.data.data;

						info.valueHeld = collectionSelected.collection.held * info.avgPrice;
						info.potentialProfit = collectionSelected.collection.currentProfit + info.valueHeld;
						info.potentialRoiIsInfinite = true;
						info.potentialRoi = 0;
						if(collectionSelected.collection.ethSpent + collectionSelected.collection.gasCost != 0) {
							info.potentialRoiIsInfinite = false;
							info.potentialRoi = info.potentialProfit / (collectionSelected.collection.ethSpent + collectionSelected.collection.gasCost);
						}
						info.valueHeld = round(info.valueHeld);
						info.potentialProfit = round(info.potentialProfit);
						info.potentialRoi = round(info.potentialRoi);

						info.avgPrice = round(info.avgPrice);
						switch(info.liquidity) {
						case "High":
							info.liquidityColor = "$gainGreen";
							break;
						case "Medium":
							info.liquidityColor = "$lightOrange";
							break;
						case "Low":
							info.liquidityColor = "$darkOrange";
							break;
						case "Dead":
							info.liquidityColor = "$lossRed";
							break;
						}

						setInfo(info);
					}
				})
				.catch(err => {
					console.log(err);
				});
		};

		useEffect(() => {
			setInfo(null);
			getCollectionInfo();
		}, [collectionSelected]);

		const handleCollectionModalClose = () => {
			setCollectionSelected({
				open: false,
				collection: null
			});
		};

		return(
			<Modal closeButton blur open={collectionSelected.open} onClose={handleCollectionModalClose} width="600px">
				{
					collectionSelected.open && info != null
						? <>
							<Modal.Header>
								<Text h3>{collectionSelected.collection.name}</Text>
							</Modal.Header>
							<Modal.Body>
								<Grid.Container>
									<Grid xs={3}>
										<div className="text-center width-100">
											<img src={info.imageUrl} className="collection-modal-image" alt="" />
										</div>
									</Grid>
									<Grid xs={9}>
										<Grid.Container>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Minted</Text>
													<Text h3 weight="bold">{collectionSelected.collection.minted}</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Avg Cost</Text>
													<Text h3 weight="bold">{collectionSelected.collection.ethMintedAvg} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Total Cost</Text>
													<Text h3 weight="bold">{collectionSelected.collection.ethMinted} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={12} className="hide-mobile">
												<Spacer y={0.5} />
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Bought</Text>
													<Text h3 weight="bold">{collectionSelected.collection.bought}</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Avg Cost</Text>
													<Text h3 weight="bold">{collectionSelected.collection.ethBoughtAvg} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Total Cost</Text>
													<Text h3 weight="bold">{collectionSelected.collection.ethBought} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={12} className="hide-mobile">
												<Spacer y={0.5} />
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Sold</Text>
													<Text h3 weight="bold">{collectionSelected.collection.sold}</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Avg Gain</Text>
													<Text h3 weight="bold">{collectionSelected.collection.ethSoldAvg} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Total Gain</Text>
													<Text h3 weight="bold">{collectionSelected.collection.ethSold} Ξ</Text>
												</div>
											</Grid>
										</Grid.Container>
									</Grid>
									<Grid xs={12} className="hide-mobile">
										<Spacer y={0.5} />
									</Grid>
									<Grid xs={12}>
										<Grid.Container>
											<Grid xs={3}>
												<div className="text-center width-100">
													<Text color="grey">Holding</Text>
													<Text h3 weight="bold">{collectionSelected.collection.held}</Text>
												</div>
											</Grid>
											<Grid xs={3}>
												<div className="text-center width-100">
													<Text color="grey">Market Price</Text>
													<Text h3 weight="bold">{info.avgPrice} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={3}>
												<div className="text-center width-100">
													<Text color="grey">Value Held</Text>
													<Text h3 weight="bold">{info.valueHeld} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={3}>
												<div className="text-center width-100">
													<Text color="grey">Liquidity</Text>
													<Text h3 weight="bold" color={info.liquidityColor}>{info.liquidity}</Text>
												</div>
											</Grid>
											<Grid xs={12} className="hide-mobile">
												<Spacer y={0.5} />
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Current Profit</Text>
													<Text h3 weight="bold" color={collectionSelected.collection.currentProfit > 0 ? "$gainGreen" : (collectionSelected.collection.currentProfit < 0 ? "$lossRed" : "")}>{collectionSelected.collection.currentProfit} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Potential Profit</Text>
													<Text h3 weight="bold" color={info.potentialProfit > 0 ? "$gainGreen" : (info.potentialProfit < 0 ? "$lossRed" : "")}>{info.potentialProfit} Ξ</Text>
												</div>
											</Grid>
											<Grid xs={4}>
												<div className="text-center width-100">
													<Text color="grey">Potential ROI</Text>
													<Text h3 weight="bold" color={info.potentialRoi > 0 ? "$gainGreen" : (info.potentialRoi < 0 ? "$lossRed" : "")}>{
														info.potentialRoiIsInfinite
															? "-"
															: `${Math.round(info.potentialRoi * 100)}%`
													}</Text>
												</div>
											</Grid>
										</Grid.Container>
									</Grid>
								</Grid.Container>
								<Spacer y={1} />
								<PoweredBy />
							</Modal.Body>
						</>
						: <Loading size="md" />
				}
			</Modal>
		);
	};

	const ChartPanel = () => {
		return(
			<>
				<Text h3>Realized Profit Over Time</Text>
				<ChartChart />
			</>
		);
	};

	const ChartChart = () => {
		if(dataSubset.closedPositions == null) {
			return(
				<Loading size="md" />
			);
		}
		if(dataSubset.closedPositions.length == 0) {
			return(
				<Text>No data</Text>
			);
		}

		let positionsSorted = JSON.parse(JSON.stringify(dataSubset.closedPositions));
		positionsSorted.sort((a, b) => a.recentActivityTimestamp - b.recentActivityTimestamp);

		let chartDataX = [];
		let chartDataCumulativeProfit = [];
		let chartDataWins = [];
		let chartDataLosses = [];
		let chartDataTooltipBeforeLabels = [], chartDataTooltipLabels = [], chartDataTooltipAfterLabels = [];

		let cumulativeProfit = 0;

		// First point for first bought
		chartDataX.push(new Date(positionsSorted[0].inTimestamp * 1000).setUTCHours(0, 0, 0, 0));
		chartDataCumulativeProfit.push(cumulativeProfit);
		chartDataWins.push(0);
		chartDataLosses.push(0);
		chartDataTooltipBeforeLabels.push("");
		chartDataTooltipLabels.push("");
		chartDataTooltipAfterLabels.push("");

		let lastDate = null;
		let positionsSinceLastPush = [];
		let hasUnpushed = false;
		for(let i = 0; i < positionsSorted.length; i++) {
			const position = positionsSorted[i];

			const date = new Date(position.recentActivityTimestamp * 1000).setUTCHours(0, 0, 0, 0);
			if(lastDate != null && date !== lastDate) {
				chartDataX.push(lastDate);
				chartDataCumulativeProfit.push(cumulativeProfit);

				let winsSinceLastPush = 0, lossesSinceLastPush = 0;
				for(let j = 0; j < positionsSinceLastPush.length; j++) {
					const positionSinceLastPush = positionsSinceLastPush[j];
					if(positionSinceLastPush.profit > 0) {
						winsSinceLastPush += positionSinceLastPush.profit;
					}
					else {
						lossesSinceLastPush += positionSinceLastPush.profit;
					}
				}
				winsSinceLastPush = round(winsSinceLastPush);
				lossesSinceLastPush = round(lossesSinceLastPush);
				chartDataWins.push(winsSinceLastPush);
				chartDataLosses.push(lossesSinceLastPush);

				positionsSinceLastPush.sort((a, b) => b.profit - a.profit);
				const mostProfitPosition = positionsSinceLastPush[0];
				if(mostProfitPosition.profit > 0) {
					chartDataTooltipBeforeLabels.push(`${mostProfitPosition.nftContractName}: +${mostProfitPosition.profit} Ξ`);
				}
				else {
					chartDataTooltipBeforeLabels.push("");
				}
				chartDataTooltipLabels.push("");
				const mostLossPosition = positionsSinceLastPush[positionsSinceLastPush.length - 1];
				if(mostLossPosition.profit < 0) {
					chartDataTooltipAfterLabels.push(`${mostLossPosition.nftContractName}: ${mostLossPosition.profit} Ξ`);
				}
				else {
					chartDataTooltipAfterLabels.push("");
				}

				positionsSinceLastPush = [];
				hasUnpushed = false;
			}
			else {
				hasUnpushed = true;
			}

			cumulativeProfit += position.profit;
			cumulativeProfit = round(cumulativeProfit);
			positionsSinceLastPush.push(position);
			lastDate = date;
		}
		if(hasUnpushed) {
			chartDataX.push(lastDate);
			chartDataCumulativeProfit.push(cumulativeProfit);

			// TODO: Refactor repetitive code
			let winsSinceLastPush = 0, lossesSinceLastPush = 0;
			for(let j = 0; j < positionsSinceLastPush.length; j++) {
				const positionSinceLastPush = positionsSinceLastPush[j];
				if(positionSinceLastPush.profit > 0) {
					winsSinceLastPush += positionSinceLastPush.profit;
				}
				else {
					lossesSinceLastPush += positionSinceLastPush.profit;
				}
			}
			winsSinceLastPush = round(winsSinceLastPush);
			lossesSinceLastPush = round(lossesSinceLastPush);
			chartDataWins.push(winsSinceLastPush);
			chartDataLosses.push(lossesSinceLastPush);

			positionsSinceLastPush.sort((a, b) => a.profit - b.profit);
			chartDataTooltipBeforeLabels.push("");
			const mostProfitPosition = positionsSinceLastPush[0];
			if(mostProfitPosition.profit > 0) {
				chartDataTooltipLabels.push(`${mostProfitPosition.nftContractName}: +${mostProfitPosition.profit} Ξ`);
			}
			else {
				chartDataTooltipLabels.push("");
			}
			const mostLossPosition = positionsSinceLastPush[positionsSinceLastPush.length - 1];
			if(mostLossPosition.profit < 0) {
				chartDataTooltipAfterLabels.push(`${mostLossPosition.nftContractName}: ${mostLossPosition.profit} Ξ`);
			}
			else {
				chartDataTooltipAfterLabels.push("");
			}
		}

		// Last point for end date
		let { endDate } = getStartAndEndDates(timePeriodDropdownSelected);
		chartDataX.push(endDate);
		chartDataCumulativeProfit.push(cumulativeProfit);
		chartDataWins.push(0);
		chartDataLosses.push(0);
		chartDataTooltipBeforeLabels.push("");
		chartDataTooltipLabels.push("");
		chartDataTooltipAfterLabels.push("");

		const data = {
			labels: chartDataX,
			datasets: [
				{
					type: "line",
					label: "Cumulative Profit",
					data: chartDataCumulativeProfit,
					borderWidth: 2,
					borderColor: "rgb(51, 162, 73)",
					order: 2
				},
				{
					type: "bar",
					label: "Wins",
					data: chartDataWins,
					backgroundColor: "rgb(51, 162, 73)",
					order: 1
				},
				{
					type: "bar",
					label: "Losses",
					data: chartDataLosses,
					backgroundColor: "rgb(239, 68, 68)",
					order: 1
				}
			]
		};

		const options = {
			responsive: true,
			scales: {
				x: {
					type: "time",
					time: {
						unit: "day"
					},
					grid: {
						drawOnChartArea: false
					},
					ticks: {
						maxTicksLimit: 10
					},
					stacked: true
				},
				y: {
					title: {
						display: true,
						text: "Cumulative Profit"
					},
					grid: {
						color: "grey"
					},
					ticks: {
						// eslint-disable-next-line no-unused-vars
						callback: (value, index, values) => {
							return `${value} Ξ`;
						}
					}
				}
			},
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					callbacks: {
						label: (context) => {
							if(context.datasetIndex != 0) {
								return "";
							}
							return `${context.dataset.label}: ${context.parsed.y} Ξ`;
						},
						beforeFooter: (context) => {
							return chartDataTooltipBeforeLabels[context[0].dataIndex];
						},
						footer: (context) => {
							return chartDataTooltipLabels[context[0].dataIndex];
						},
						afterFooter: (context) => {
							return chartDataTooltipAfterLabels[context[0].dataIndex];
						}
					},
					xAlign: "center",
					yAlign: "bottom",
					displayColors: false,
					titleFont: {
						weight: "normal"
					},
					bodyFont: {
						weight: "bold",
						size: 14
					}
				}
			},
			interaction: {
				mode: "index",
				intersect: false
			},
			fill: {
				target: "origin",
				above: "rgba(142, 222, 170, 0.3)",
				below: "rgba(142, 222, 170, 0.3)",
				order: 3
			},
			pointRadius: 0,
			pointHoverRadius: 5,
			pointHoverBackgroundColor: "rgb(51, 162, 73)",
			pointHoverBorderWidth: 8,
			pointHoverBorderColor: "rgba(51, 162, 73, 0.5)",
			stepped: true
		};

		return(
			<Chart data={data} options={options} />
		);
	};

	const MePanel = (props) => {
		if(!props.isSignedIn) {
			return(
				<SignInCard />
			);
		}

		return(
			<WalletGroupCard data={props.data.walletGroupAddresses} />
		);
	};

	const MeOrTraderProfileCard = (props) => {
		if(props.isMe && props.isSignedIn) {
			return(
				<CustomProfileCard address={props.address} imageSize={50} name="My Wallets" underName={`${props.data.walletGroupAddresses.length} wallet${props.data.walletGroupAddresses.length == 1 ? "" : "s"}`} canCopy={false} poweredBy={true} padding="30px" hideBorderOnMobile={false}>
					{props.children}
				</CustomProfileCard>
			);
		}

		return(
			<ProfileCard address={props.address} imageSize={50} poweredBy={true} padding="30px" hideBorderOnMobile={false}>
				{props.children}
			</ProfileCard>
		);
	};
	MeOrTraderProfileCard.propTypes = {
		children: PropTypes.node
	};

	return(
		<>
			{
				props.isMe
					? <Grid xs={12}>
						<MePanel isSignedIn={props.isSignedIn} data={props.data} />
					</Grid>
					: <></>
			}
			<Grid xs={12}>
				<UnlockedCard poweredBy={false} padding="30px" hideBorderOnMobile={true}>
					<ConfigPanel data={props.data} />
				</UnlockedCard>
			</Grid>
			<Grid xs={12} sm={4}>
				<MeOrTraderProfileCard isMe={props.isMe} isSignedIn={props.isSignedIn} address={props.address} data={props.data}>
					<StatsPanel />
				</MeOrTraderProfileCard>
			</Grid>
			<Grid xs={12} sm={8}>
				<UnlockedCard poweredBy={true} padding="30px" hideBorderOnMobile={true}>
					<FlipsPanel />
				</UnlockedCard>
			</Grid>
			<Grid xs={12}>
				<UnlockedCard poweredBy={true} padding="30px" hideBorderOnMobile={true}>
					<CollectionsPanel />
				</UnlockedCard>
			</Grid>
			<Grid xs={12} className="hide-mobile">
				<UnlockedCard poweredBy={true} padding="30px" hideBorderOnMobile={true}>
					<ChartPanel />
				</UnlockedCard>
			</Grid>
		</>
	);
};
Trader.propTypes = {
	address: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	isMe: PropTypes.bool.isRequired,
	isSignedIn: PropTypes.bool.isRequired
};

export default Trader;
