import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Text, Textarea, Spacer, Loading, Tooltip } from "@nextui-org/react";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { useCookies } from "react-cookie";
import axios from "axios";

import SignInCard from "./SignInCard.jsx";
import UnlockedCard from "./UnlockedCard.jsx";

const WalletGroupCard = (props) => {
	const [cookies] = useCookies(["signature"]);

	const [editModalVisible, setEditModalVisible] = useState(false);
	const [savedEditModalInput, setSavedEditModalInput] = useState(props.data.join(", "));

	if(cookies["signature"] === undefined) {
		return(
			<SignInCard />
		);
	}

	const WalletLabel = (props) => {
		const truncatedAddress = `${props.address.substring(0, 6)}...${props.address.substring(38)}`;

		const [copyClicked, setCopyClicked] = useState(false);

		const handleCopyClicked = () => {
			navigator.clipboard.writeText(props.address);
			setCopyClicked(true);
		};

		return(
			<span className="wallet-label">
				<Tooltip content={copyClicked ? "Copied!" : "Copy"}>
					<Jazzicon diameter="12px" seed={jsNumberForAddress(props.address)} />
					<span style={{ marginLeft: "5px", cursor: "pointer" }} onClick={handleCopyClicked}>{truncatedAddress}</span>
				</Tooltip>
			</span>
		);
	};
	WalletLabel.propTypes = {
		address: PropTypes.string.isRequired
	};

	const handleEditModalOpen = () => {
		setEditModalVisible(true);
	};

	const EditModal = () => {
		const [editModalInput, setEditModalInput] = useState(savedEditModalInput);
		const [editModalSaving, setEditModalSaving] = useState(false);
		const [editModalError, setEditModalError] = useState(null);

		const handleEditModalClose = () => {
			setSavedEditModalInput(editModalInput);
			setEditModalVisible(false);
		};
		const handleEditModalCancel = () => {
			setEditModalVisible(false);
		};
		const handleEditModalSave = () => {
			setEditModalSaving(true);

			axios.get(`${process.env.REACT_APP_EXPRESS_URL}/editWalletGroup`, {
				params: {
					address: cookies["signature"].address,
					signature: cookies["signature"].signature,
					walletGroup: editModalInput
				}
			})
				.then(res => {
					if(res.data.status == "error") {
						setEditModalError(res.data.error);
						setEditModalSaving(false);
						return;
					}
					window.location.reload();
				})
				.catch(err => {
					setEditModalError(err);
					setEditModalSaving(false);
				});
		};

		return(
			<Modal closeButton open={editModalVisible} onClose={handleEditModalClose} width="500px">
				<Modal.Header>
					<Text h3>Edit Wallets</Text>
				</Modal.Header>
				<Modal.Body>
					<Textarea label="Enter wallet addresses separated by commas or new lines" initialValue={editModalInput} onInput={e => setEditModalInput(e.target.value)} bordered />
					{
						editModalError
							? <Text color="error">Error: {JSON.stringify(editModalError)}</Text>
							: <></>
					}
				</Modal.Body>
				<Modal.Footer>
					{
						editModalSaving
							? <></>
							: <Button auto color="error" onClick={handleEditModalCancel}>Cancel</Button>
					}
					<Button auto disabled={editModalSaving} onClick={handleEditModalSave}>
						{
							editModalSaving
								? <Loading type="points-opacity" color="currentColor" />
								: <>Save</>
						}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	const Content = (props) => {
		const getWalletLabels = () => {
			let walletLabels = [];
			for(let i = 0; i < props.data.length; i++) {
				const walletAddress = props.data[i];
				walletLabels.push(<WalletLabel key={i} address={walletAddress} />);
			}
			return walletLabels;
		};

		return(
			<>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Text h3 css={{ margin: 0 }}>My Wallets</Text>
					<Button auto size="xs" css={{ marginLeft: "10px" }} onClick={handleEditModalOpen}>Edit</Button>
				</div>
				<Spacer y={0.5} />
				<div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>{getWalletLabels()}</div>
				<EditModal />
			</>
		);
	};

	return(
		<UnlockedCard poweredBy={false} padding="30px" hideBorderOnMobile={false}>
			<Content data={props.data} />
		</UnlockedCard>
	);
};
WalletGroupCard.propTypes = {
	data: PropTypes.array.isRequired
};

export default WalletGroupCard;
