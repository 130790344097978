import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Loading } from "@nextui-org/react";

import ReactGA from "react-ga4";
import axios from "axios";

const SearchBar = () => {
	const navigate = useNavigate();
	const [searchInput, setSearchInput] = useState("");
	const [isSearching, setIsSearching] = useState(false);

	const onKeyDown = (event) => {
		if(event.key != "Enter") {
			return;
		}
		search();
	};

	const search = () => {
		console.log(`search ${searchInput}`);
		setIsSearching(true);

		ReactGA.event("search", { search_term: searchInput });

		axios.get(`${process.env.REACT_APP_EXPRESS_URL}/checkAddress`, {
			params: {
				address: searchInput
			}
		})
			.then(res => {
				setIsSearching(false);
				if(res.data.status == "error" || !res.data.data.valid) {
					return;
				}
				navigate(`/${res.data.data.address}`);
				setSearchInput("");
				document.activeElement.blur();
			})
			.catch(() => {
				setIsSearching(false);
			});
	};

	const SearchIcon = () => {
		if(isSearching) {
			return(
				<Loading size="xs" />
			);
		}
		return(
			<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/></svg>
		);
	};

	return(
		<div style={{ maxWidth: "450px", width: "100%", display: "inline-block" }}>
			<Input placeholder="Search any ENS name or Ethereum address" value={searchInput} fullWidth rounded contentRight={<SearchIcon />} onInput={e => setSearchInput(e.target.value)} onKeyDown={onKeyDown} onBlur={search} />
		</div>
	);
};

export default SearchBar;
