import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Spacer, Text } from "@nextui-org/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import ReactGA from "react-ga4";
import { useAccount } from "wagmi";

import SearchBar from "../components/SearchBar.jsx";

const Landing = () => {
	const navigate = useNavigate();
	const { isConnected } = useAccount();

	useEffect(() => {
		if(isConnected) {
			navigate("/home");
		}
	}, [isConnected]);

	if(isConnected) {
		return(
			<Text>Wallet not detected! Redirecting...</Text>
		);
	}

	ReactGA.send({ hitType: "pageview", page: "/landing" });

	return(
		<div className="text-center">
			<Spacer y={5} />
			<img src="/logolong.svg" alt="Logo" style={{ maxHeight: "80px" }} draggable="false" />
			<Spacer y={1} />
			<SearchBar />
			<Spacer y={1} />
			<Container fluid display="flex" justify="center">
				<ConnectButton accountStatus="address" showBalance={false} />
			</Container>
		</div>
	);
};

export default Landing;
