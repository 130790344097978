import React from "react";
import PropTypes from "prop-types";
import { Card, Spacer } from "@nextui-org/react";

import PoweredBy from "./PoweredBy.jsx";

const UnlockedCard = (props) => {
	const content = <>
		{props.children}
		{
			props.poweredBy
				? <>
					<Spacer y={1} />
					<PoweredBy />
				</>
				: <></>
		}
	</>;

	if(props.hideBorderOnMobile) {
		return(
			<div style={{ width: "100%" }}>
				<Card variant="shadow" css={{ "@xsMax": { display: "none" } }}>
					<Card.Body css={{ padding: props.padding }}>
						{content}
					</Card.Body>
				</Card>
				<div className="show-mobile">
					{content}
				</div>
			</div>
		);
	}

	return(
		<Card variant="shadow">
			<Card.Body css={{ padding: props.padding }}>
				{content}
			</Card.Body>
		</Card>
	);
};
UnlockedCard.propTypes = {
	poweredBy: PropTypes.bool.isRequired,
	padding: PropTypes.string.isRequired,
	hideBorderOnMobile: PropTypes.bool.isRequired,
	children: PropTypes.node
};

export default UnlockedCard;
