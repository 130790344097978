import React from "react";
import { Text } from "@nextui-org/react";

const PoweredBy = () => {
	return(
		<Text className="text-center" color="grey" weight="bold">Powered by DATALPHA.io</Text>
	);
};

export default PoweredBy;
